import { authApi } from '@app/api/http.api';
// import './mocks/auth.api.mock';
import { UserModel } from '@app/domain/UserModel';
import { CognitoIdentityProviderClient, InitiateAuthCommand, InitiateAuthCommandInput, SignUpCommand, ConfirmSignUpCommand, GetUserCommand, AdminAddUserToGroupCommand } from "@aws-sdk/client-cognito-identity-provider";
import { jwtDecode } from 'jwt-decode';
import { config } from "../config";
import { encode } from 'querystring';
import axios, { AxiosRequestConfig } from "axios";

export interface AuthData {
  email: string;
  password: string;
}

export interface SignUpRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  role: string;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface SecurityCodePayload {
  email: string;
  code: string;
}

export interface NewPasswordData {
  newPassword: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface LoginResponse {
  token: string;
  user: UserModel;
}

export interface  OAuth2Request {
  code: string;
}
export interface  OAuth2Response {
  accessToken: string;
  idToken: string;
  refreshToken: string;
}
//
// export const login = (loginPayload: LoginRequest): Promise<LoginResponse> =>
//   authApi.post<LoginResponse>('/dev/user/login', { ...loginPayload }).then(({ data }) => data);
//
// export const signUp = (signUpData: SignUpRequest): Promise<undefined> =>
//   authApi.post<undefined>('/Prod/signup', { ...signUpData }).then(({ data }) => data);
//
export const resetPassword = (resetPasswordPayload: ResetPasswordRequest): Promise<undefined> =>
  authApi.post<undefined>('forgotPassword', { ...resetPasswordPayload }).then(({ data }) => data);

// export const verifySecurityCode = (securityCodePayload: SecurityCodePayload): Promise<undefined> =>
//   authApi.post<undefined>('verifySecurityCode', { ...securityCodePayload }).then(({ data }) => data);

export const setNewPassword = (newPasswordData: NewPasswordData): Promise<undefined> =>
  authApi.post<undefined>('setNewPassword', { ...newPasswordData }).then(({ data }) => data);

export const cognitoClient = new CognitoIdentityProviderClient({
  region: config.region,
});

const adminClient = new CognitoIdentityProviderClient({
  region: "us-east-1",
  credentials: {
    accessKeyId: "",
    secretAccessKey: "",
  },
});

export const login = async (loginPayload: LoginRequest) => {
  const params: InitiateAuthCommandInput = {
    AuthFlow: "USER_PASSWORD_AUTH",
    ClientId: config.clientId,
    AuthParameters: {
      USERNAME: loginPayload.email,
      PASSWORD: loginPayload.password,
    },
  };
  try {
    const command = new InitiateAuthCommand(params);
    const { AuthenticationResult } = await cognitoClient.send(command);
    console.log(AuthenticationResult);
    if (AuthenticationResult) {
      sessionStorage.setItem("idToken", AuthenticationResult.IdToken || '');
      sessionStorage.setItem("accessToken", AuthenticationResult.AccessToken || '');
      sessionStorage.setItem("refreshToken", AuthenticationResult.RefreshToken || '');


      const idToken = AuthenticationResult.IdToken || '';
      const accessToken = AuthenticationResult.AccessToken || '';
      const decodedToken = jwtDecode(idToken) as any;
      console.log('ID Token Decoded:', decodedToken);

      // Get user info
      const userInfoCommand = new GetUserCommand({
        AccessToken: accessToken,
      });

      const userInfoResponse = await cognitoClient.send(userInfoCommand);
      console.log("User attributes:", userInfoResponse.UserAttributes);

      // if (userInfoResponse && userInfoResponse.UserAttributes) {
      //   // Extract specific attributes
      //   const givenName = decodedToken.given_name || userInfoResponse.UserAttributes.find(attr => attr.Name === 'given_name')?.Value;
      //   const familyName = decodedToken.family_name || userInfoResponse.UserAttributes.find(attr => attr.Name === 'family_name')?.Value;
      //   const picture = decodedToken.picture || userInfoResponse.UserAttributes.find(attr => attr.Name === 'picture')?.Value;
      //
      //   console.log('Given Name:', givenName);
      //   console.log('Family Name:', familyName);
      //   console.log('Picture:', picture);
      // }
      let firstName = '';
      let lastName = '';
      if (decodedToken['given_name']) {
        firstName = decodedToken['given_name'];
        lastName = decodedToken['family_name'];
      }
      if (decodedToken['name']) {
        const fullName = decodedToken['name'].split(' ');
        firstName = fullName[0] || '';
        lastName = fullName[1] || '';
      }
      return {
        token: accessToken,
        refreshToken: AuthenticationResult.RefreshToken,
        user: {
          id: decodedToken['email'],
          name: decodedToken['name'],
          firstName: firstName,
          lastName: lastName,
          loginID: decodedToken['cognito:username'],
          email: decodedToken['email'],
          role: decodedToken['email'],
          imgUrl: decodedToken['photo'],
          phone: decodedToken['phone'],
          exp: decodedToken['exp'],
          auth_time: decodedToken['auth_time'],
        },
      };
    } else {
      console.error("Error signing in: AuthenticationResult is empty");
      throw new Error("Error signing in: AuthenticationResult is empty");
    }
  } catch (error) {
    console.error("Error signing in: ", error);
    throw error;
  }
};

export const signUp = async (signUpData: SignUpRequest) => {
  const params = {
    ClientId: config.clientId,
    Username: signUpData.email,
    Password: signUpData.password,
    UserAttributes: [
      {
        Name: "email",
        Value: signUpData.email,
      },
      {
        "Name": "name",
        "Value": signUpData.firstName + " " + signUpData.lastName,
      },
    ],
  };
  try {
    const command = new SignUpCommand(params);
    const response = await cognitoClient.send(command);
    console.log("Sign up success: ", response);

    // // Add the user to a group
    // const groupName = "normal";
    // const addGroupParams = {
    //   UserPoolId: config.userPoolId,
    //   Username: signUpData.email,
    //   GroupName: groupName,
    // };
    // const addGroupCommand = new AdminAddUserToGroupCommand(addGroupParams);
    // const addGroupResponse = await adminClient.send(addGroupCommand);
    // console.log(`User added to group ${groupName}:`, addGroupResponse);

    return response;
  } catch (error) {
    console.error("Error signing up: ", error);
    throw error;
  }
};

export const verifyOAuth2 = async (oAuth2Payload: OAuth2Request) => {
  try {
    const data = encode({
      grant_type: 'authorization_code',
      client_id: config.clientId,
      redirect_uri: config.redirectUri,
      ...oAuth2Payload
    });

    const httpConfig: AxiosRequestConfig = {
      method: 'post',
      url: "https://blinderai.auth.us-east-1.amazoncognito.com/oauth2/token",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    };

    const result = await axios(httpConfig);
    console.log('---verifyOAuth2 result---', result);

    sessionStorage.setItem("idToken", result.data?.id_token || '');
    sessionStorage.setItem("accessToken", result.data?.access_token || '');
    sessionStorage.setItem("refreshToken", result.data?.refresh_token || '');


    const idToken = result.data?.id_token || '';
    const accessToken = result.data?.access_token || '';
    const decodedToken = jwtDecode(idToken) as any;
    console.log('ID Token Decoded:', decodedToken);

    let firstName = '';
    let lastName = '';
    if (decodedToken['given_name']) {
      firstName = decodedToken['given_name'];
      lastName = decodedToken['family_name'];
    }
    if (decodedToken['name']) {
      const fullName = decodedToken['name'].split(' ');
      firstName = fullName[0] || '';
      lastName = fullName[1] || '';
    }
    return {
      token: accessToken,
      refreshToken: result.data?.refresh_token,
      user: {
        id: decodedToken['email'],
        firstName: firstName,
        lastName: lastName,
        loginID: decodedToken['cognito:username'],
        email: decodedToken['email'],
        role: decodedToken['email'],
        imgUrl: decodedToken['photo'],
        phone: decodedToken['phone'],
        exp: decodedToken['exp'],
        auth_time: decodedToken['auth_time'],
      },
    };
  } catch (error) {
    console.error("Error signing in: ", error);
    throw error;
  }
};

export const verifyOAuth2Lambda = async (oAuth2Payload: OAuth2Request) => {
  try {
    const result = await authApi.post<OAuth2Response>('verify', { ...oAuth2Payload });
    console.log(result);
    sessionStorage.setItem("idToken", result.data?.idToken || '');
    sessionStorage.setItem("accessToken", result.data?.accessToken || '');
    sessionStorage.setItem("refreshToken", result.data?.refreshToken || '');


    const idToken = result.data?.idToken || '';
    const accessToken = result.data?.accessToken || '';
    const decodedToken = jwtDecode(idToken) as any;
    console.log('ID Token Decoded:', decodedToken);

    let firstName = '';
    let lastName = '';
    if (decodedToken['given_name']) {
      firstName = decodedToken['given_name'];
      lastName = decodedToken['family_name'];
    }
    if (decodedToken['name']) {
      const fullName = decodedToken['name'].split(' ');
      firstName = fullName[0] || '';
      lastName = fullName[1] || '';
    }
    return {
      token: accessToken,
      user: {
        id: decodedToken['email'],
        firstName: firstName,
        lastName: lastName,
        loginID: decodedToken['cognito:username'],
        email: decodedToken['email'],
        role: decodedToken['email'],
        imgUrl: decodedToken['photo'],
        phone: decodedToken['phone'],
      },
    };
  } catch (error) {
    console.error("Error signing in: ", error);
    throw error;
  }
};


export const verifySecurityCode = async (securityCodePayload: SecurityCodePayload) => {
  const params = {
    ClientId: config.clientId,
    Username: securityCodePayload.email,
    ConfirmationCode: securityCodePayload.code,
  };
  try {
    const command = new ConfirmSignUpCommand(params);
    await cognitoClient.send(command);
    console.log("User confirmed successfully");
    return true;
  } catch (error) {
    console.error("Error confirming sign up: ", error);
    throw error;
  }
};

// export const verifySecurityCode = (securityCodePayload: SecurityCodePayload): Promise<undefined> =>
//   authApi.post<undefined>('verifySecurityCode', { ...securityCodePayload }).then(({ data }) => data);

export const refreshToken = async (rtoken: string) => {
  const params: InitiateAuthCommandInput = {
    AuthFlow: "REFRESH_TOKEN_AUTH",
    ClientId: config.clientId,
    AuthParameters: {
      'REFRESH_TOKEN': rtoken,
    },
  };
  try {
    const command = new InitiateAuthCommand(params);
    const { AuthenticationResult } = await cognitoClient.send(command);
    console.log(AuthenticationResult);
    if (AuthenticationResult) {
      sessionStorage.setItem("idToken", AuthenticationResult.IdToken || '');
      sessionStorage.setItem("accessToken", AuthenticationResult.AccessToken || '');
      sessionStorage.setItem("refreshToken", AuthenticationResult.RefreshToken || '');


      const idToken = AuthenticationResult.IdToken || '';
      const accessToken = AuthenticationResult.AccessToken || '';
      const decodedToken = jwtDecode(idToken) as any;
      console.log('ID Token Decoded:', decodedToken);

      // Get user info
      const userInfoCommand = new GetUserCommand({
        AccessToken: accessToken,
      });

      const userInfoResponse = await cognitoClient.send(userInfoCommand);
      console.log("User attributes:", userInfoResponse.UserAttributes);

      let firstName = '';
      let lastName = '';
      if (decodedToken['given_name']) {
        firstName = decodedToken['given_name'];
        lastName = decodedToken['family_name'];
      }
      if (decodedToken['name']) {
        const fullName = decodedToken['name'].split(' ');
        firstName = fullName[0] || '';
        lastName = fullName[1] || '';
      }
      return {
        token: accessToken,
        user: {
          id: decodedToken['email'],
          firstName: firstName,
          lastName: lastName,
          loginID: decodedToken['cognito:username'],
          email: decodedToken['email'],
          role: decodedToken['email'],
          imgUrl: decodedToken['photo'],
          phone: decodedToken['phone'],
          exp: decodedToken['exp'],
          auth_time: decodedToken['auth_time'],
        },
      };
    } else {
      console.error("Error signing in: AuthenticationResult is empty");
      throw new Error("Error signing in: AuthenticationResult is empty");
    }
  } catch (error) {
    console.error("Error signing in: ", error);
    throw error;
  }
};
