import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { HomeOutlined, SoundOutlined, FileAddOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { NotificationsDropdown } from '../components/notificationsDropdown/NotificationsDropdown';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { HeaderSearch } from '../components/HeaderSearch/HeaderSearch';
import { SettingsDropdown } from '../components/settingsDropdown/SettingsDropdown';
import { HeaderFullscreen } from '../components/HeaderFullscreen/HeaderFullscreen';
import * as S from '../Header.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import notFoundImg from "@app/assets/images/nothing-found.webp";
import { useResponsive } from '@app/hooks/useResponsive';
import { BaseButton } from "@app/components/common/BaseButton/BaseButton";

interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean;
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({ isTwoColumnsLayout }) => {
  const { pathname } = useLocation();
  const { mobileOnly, isTablet, isDesktop } = useResponsive();

  // const leftSide = isTwoColumnsLayout ? (
  //   <S.SearchColumn xl={16} xxl={17}>
  //     <BaseRow justify="space-between">
  //       <BaseCol xl={15} xxl={12}>
  //         <HeaderSearch />
  //       </BaseCol>
  //       <BaseCol>
  //         <S.GHButton />
  //       </BaseCol>
  //     </BaseRow>
  //   </S.SearchColumn>
  // ) : (
  //   <>
  //     <S.LogoColumn>
  //       <BaseRow justify="start" align="middle" style={{marginTop: '-15px'}}>
  //         <Link to="/">
  //           <S.Image src="https://basicannon.s3.amazonaws.com/blinder+Main+Logo.png" alt="Blinder" preview={false} style={{height: '50px'}}  />
  //         </Link>
  //         <Link to="/">
  //           <S.Image src="https://basicannon.s3.amazonaws.com/cancellation-x.svg" alt="x" preview={false} style={{height: '30px'}} />
  //         </Link>
  //         <Link to="/"><img src="https://s3.amazonaws.com/blinder.copyright/seal2004-removebg-preview.png" alt="govCopyright" style={{height: '80px'}} /></Link>
  //       </BaseRow>
  //     </S.LogoColumn>
  //   </>
  // );

  return (
    <>
    <BaseRow justify="space-between" align="top">
      <S.LogoColumn>
        <BaseRow justify="start" align="middle" style={{marginTop: '-15px', height: '80px'}}>
          <Link to="/">
            <S.Image src="https://basicannon.s3.amazonaws.com/blinder+Main+Logo.png" alt="Blinder" preview={false} style={{height: '50px'}}  />
          </Link>
          {/* {isDesktop && (  
            <>
              <Link to="/">
                <S.Image src="https://basicannon.s3.amazonaws.com/cancellation-x.svg" alt="x" preview={false} style={{height: '30px'}} />
              </Link>
              <Link to="/"><img src="https://s3.amazonaws.com/blinder.copyright/seal2004-removebg-preview.png" alt="govCopyright" style={{height: '80px'}} /></Link>
            </>
          )} */}
        </BaseRow>
      </S.LogoColumn>
      <S.MenuColumn>
        <BaseRow>
          <BaseCol style={{flex: 1, display: 'flex', justifyContent: 'end', marginRight: '20px',}}>
            <Space>
              <Link to="/home">
                <BaseButton type="primary">Home</BaseButton>
              </Link>
              {/* <Link to="/voice">
                <BaseButton type="primary"><SoundOutlined />Voice</BaseButton>
              </Link> */}
              {pathname!=='/file'?(
                <Link to="/file">
                    <BaseButton type="primary">Protect Your IP</BaseButton>
                </Link>
              ):(
                <Link to="/home">
                  <BaseButton type="primary">Protect Your Voice</BaseButton>
                </Link>
              )}
            </Space>
          </BaseCol>
          <BaseCol>
            <HeaderSearch />
          </BaseCol>
        </BaseRow>
      </S.MenuColumn>
      <S.ProfileColumn $isTwoColumnsLayout={isTwoColumnsLayout}>
        <BaseRow>
          {/* <BaseCol>
            <BaseRow gutter={[{ xxl: 5 }, { xxl: 5 }]} wrap={false}>
              <BaseCol>
                <HeaderFullscreen />
              </BaseCol>

              <BaseCol>
                <NotificationsDropdown />
              </BaseCol>

              <BaseCol>
                <SettingsDropdown />
              </BaseCol>
            </BaseRow>
          </BaseCol> */}
          <BaseCol>
            <ProfileDropdown />
          </BaseCol>
        </BaseRow>
      </S.ProfileColumn>
    </BaseRow>
    <BaseRow>Mobile experience coming soon, please use your computer for the full experience now.</BaseRow>
    </>
  );
};
