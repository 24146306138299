import React, { useEffect, useState } from 'react';
import { Header } from '../../../header/Header';
import MainSider from '../sider/MainSider/MainSider';
import MainContent from '../MainContent/MainContent';
import { MainHeader } from '../MainHeader/MainHeader';
import { useTranslation } from 'react-i18next';
import * as S from './MainLayout.styles';
import * as BS from '@app/pages/Blinder/Blinder.styles';
import { Outlet, useLocation } from 'react-router-dom';
import { MEDICAL_DASHBOARD_PATH, NFT_DASHBOARD_PATH } from '@app/components/router/AppRouter';
import { useResponsive } from '@app/hooks/useResponsive';
import { References } from '@app/components/common/References/References';

const MainLayout: React.FC = () => {
  const { t } = useTranslation();
  const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(true);
  const [siderCollapsed, setSiderCollapsed] = useState(true);
  const { isDesktop } = useResponsive();
  const location = useLocation();

  const toggleSider = () => setSiderCollapsed(!siderCollapsed);

  useEffect(() => {
    // setIsTwoColumnsLayout([MEDICAL_DASHBOARD_PATH, NFT_DASHBOARD_PATH].includes(location.pathname) && isDesktop);
    setIsTwoColumnsLayout(false);
  }, [location.pathname, isDesktop]);

  return (
    <S.LayoutMaster>
      {/*<MainSider isCollapsed={siderCollapsed} setCollapsed={setSiderCollapsed} />*/}
      <S.LayoutMain>
        <MainHeader isTwoColumnsLayout={isTwoColumnsLayout}>
          <Header toggleSider={toggleSider} isSiderOpened={!siderCollapsed} isTwoColumnsLayout={isTwoColumnsLayout} />
        </MainHeader>
        <MainContent id="main-content" $isTwoColumnsLayout={isTwoColumnsLayout}>
          <S.FullContent>
            <Outlet />
          </S.FullContent>
          {/*{!isTwoColumnsLayout && <References />}*/}
          <BS.TextCenter>
            <BS.StyledRow align="center">
              <BS.Link href="https://clerkbridge.com/privacy-policy/" target="_blank">{t('blinder.text.privacyPolicy')}</BS.Link>
              <BS.VirtBar />
              <BS.Link href="/terms_conditions" target="_blank">{t('blinder.text.termsOfService')}</BS.Link>
            </BS.StyledRow>
            <BS.StyledRow align="center">
              <BS.Text>THE SERVICES ARE NOT LEGAL ADVICE AND SHOULD NOT BE USED A SUBSTITUTE FOR LEGAL AND PROFESSIONAL ADVICE AND CUSTOMER&apos;S OWN JUDGMENT.</BS.Text>
            </BS.StyledRow>
            <BS.StyledRow align="center">
              <BS.Text> © 2024 Blinder, LLC. All rights reserved.</BS.Text>
            </BS.StyledRow>
          </BS.TextCenter>
        </MainContent>
      </S.LayoutMain>
    </S.LayoutMaster>
  );
};

export default MainLayout;
