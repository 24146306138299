import React from 'react';
import { Link } from 'react-router-dom';
import { NotificationsDropdown } from '../components/notificationsDropdown/NotificationsDropdown';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { HeaderSearch } from '../components/HeaderSearch/HeaderSearch';
import { SettingsDropdown } from '../components/settingsDropdown/SettingsDropdown';
import * as S from '../Header.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';

interface MobileHeaderProps {
  toggleSider: () => void;
  isSiderOpened: boolean;
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({ toggleSider, isSiderOpened }) => {
  return (
    <BaseRow align="middle">
      <BaseCol>
        <ProfileDropdown />
      </BaseCol>
      <BaseCol>
        <Link to="/">
          <S.Image src="https://basicannon.s3.amazonaws.com/blinder+Main+Logo.png" alt="Blinder" preview={false} style={{height: '40px'}}  />
        </Link>
      </BaseCol>
      <BaseCol style={{flex: 1}} />
      <BaseCol>
        <HeaderSearch />
      </BaseCol>
{/* 
      <BaseCol>
        <BaseRow align="middle">
          <BaseCol>
            <NotificationsDropdown />
          </BaseCol>

          <BaseCol>
            <HeaderSearch />
          </BaseCol>

          <BaseCol>
            <SettingsDropdown />
          </BaseCol>
        </BaseRow> 
      </BaseCol>
*/}
      <S.BurgerCol>
        <S.MobileBurger onClick={toggleSider} isCross={isSiderOpened} />
      </S.BurgerCol>
    </BaseRow>
  );
};
